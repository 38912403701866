




import { Component } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import ChartBase from '../ChartBase';
import PieChartData from './modelsPie';

@Component
export default class ChartPie extends ChartBase {
  declare chartData: PieChartData;

  options: ApexOptions = {};

  series: number[] | null = null;

  onInitOrUpdate(): void {
    console.log('init or update');
    this.options = {
      chart: {
        id: '',
      },
      labels: this.queryResult.map((x: any) => x[this.chartData.labelsField]),
    };

    if (!this.chartData.seriesField) return;

    this.series = this.queryResult.map((x: any) => Number(x[this.chartData.seriesField]));
    console.log('sss', this.series);
  }
}
